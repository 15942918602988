import { createStore } from "vuex";

const emptyGeo = {
  country: null,
  state: null,
  counties: null,
};
const emptyCons = {
  insitu: null,
  exsitu: null,
  combined: null,
};
const emptyTaxon = {
  genus: null,
  crop: null,
  type: null,
  category: null,
};

const store = createStore({
  state() {
    return {
      geoFilter: emptyGeo,
      consFilter: emptyCons,
      taxFilter: emptyTaxon,
      mapTaxon: [],
      dlFormSubmitted: false,
    };
  },
  mutations: {
    setMapTaxon(state, taxon) {
      state.mapTaxon = taxon;
    },
    // geometry filter set
    updateGeoFilter(state, filter) {
      state.geoFilter = filter;
    },
    // conservation priority filter set
    updateConsFilter(state, filter) {
      state.consFilter = filter;
    },
    // taxonomy filter set
    updateTaxFilter(state, filter) {
      state.taxFilter = filter;
    },
    // Array of species objects
    updateTaxon(state, taxon) {
      state.mapTaxon = taxon;
    },
    toggleDlFormSubmitted(state) {
      state.dlFormSubmitted = !state.dlFormSubmitted;
    },
  },
  actions: {
    reset(context) {
      context.commit("updateGeoFilter", emptyGeo);
      context.commit("updateConsFilter", emptyCons);
      context.commit("updateTaxFilter", emptyTaxon);
      context.commit("updateTaxon", []);
    },
  },
});

export default store;
