import Point from "@arcgis/core/geometry/Point";

export const DEFAULT_POINT = Object.freeze(
  new Point({
    latitude: 39.8283,
    longitude: -98.5795,
  })
);

export const ADMIN_HIGHLIGHT_LINE = {
  type: "simple-fill",
  style: "none",
  outline: {
    color: "red",
    width: 3,
  },
};