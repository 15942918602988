<template>
  <form>
    <div class="flex flex-column">
      <div class="flex-none">
        <h4>Conservation Priority Filters</h4>
      </div>
      <div class="field flex-none">
        <label for="exsitu">Priority for ex situ conservation </label>
        <MultiSelect
          id="exsitu"
          v-model="selExSitu"
          :options="priorties"
          optionLabel="name"
          :filter="true"
          placeholder="Select Priority"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="emitUpdate"
          class="w-full"
        />
      </div>
      <Divider class="m-0 p-0 mt-1 mb-1" />
      <div class="field flex-none">
        <label>Priority for in situ conservation</label>
        <MultiSelect
          v-model="selInSitu"
          :options="priorties"
          optionLabel="name"
          :filter="true"
          placeholder="Select Priority"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="emitUpdate"
          class="w-full"
        />
      </div>
      <Divider class="m-0 p-0 mt-1 mb-1" />
      <div class="field flex-none">
        <label>Priority for conservation (combined)</label>
        <MultiSelect
          v-model="selCombCons"
          :options="priorties"
          optionLabel="name"
          :filter="true"
          placeholder="Select Priority"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="emitUpdate"
          class="w-full"
        />
      </div>
      <div class="flex-none">
        <Button
          label="Clear Priority Filters"
          icon="pi pi-filter-slash"
          iconPos="left"
          class="p-button-secondary w-full border-1 transition-none"
          aria-label="reset geofilters"
          aria:haspopup="false"
          @click="reset"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import MultiSelect from "primevue/multiselect";
import Divider from "primevue/divider";
import Button from "primevue/button";

export default {
  name: "ConsFilterContent",
  components: {
    MultiSelect,
    Divider,
    Button,
  },

  setup() {
    const store = useStore();
    const selExSitu = ref();
    const selInSitu = ref();
    const selCombCons = ref();

    const priorties = ref([
      { name: "Urgent", code: "UP" },
      { name: "High", code: "HP" },
      { name: "Medium", code: "MP" },
      { name: "Low", code: "LP" },
    ]);

    onMounted(() => {
      var fltrStore = store.state.consFilter;
      selInSitu.value = fltrStore.insitu;
      selExSitu.value = fltrStore.exsitu;
      selCombCons.value = fltrStore.combined;
    });

    function reset() {
      selExSitu.value = null;
      selInSitu.value = null;
      selCombCons.value = null;
      emitUpdate();
    }

    function emitUpdate() {
      var updatedFilters = {
        insitu: selInSitu.value,
        exsitu: selExSitu.value,
        combined: selCombCons.value,
      };
      store.commit("updateConsFilter", updatedFilters);
    }

    return {
      selExSitu,
      priorties,
      selInSitu,
      selCombCons,
      emitUpdate,
      reset,
    };
  },
};
</script>

<style scoped></style>
