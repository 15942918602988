const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export const getDecFixedLocale = function (number, fixedDecimal) {
  return number?.toLocaleString(userLocale, {
    minimumFractionDigits: fixedDecimal,
    maximumFractionDigits: fixedDecimal,
  });
};

export const getNumLocale = function (number) {
  return number?.toLocaleString();
};
