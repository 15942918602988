import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
import Query from "@arcgis/core/rest/support/Query";

import { colorize } from "./recolor.js";

import {
  getCategoryPrettyName,
  getNumStringConcat,
} from "../utils/nameUtils.js";

const EXGAPS_IMG_LAYER =
  "https://pdi.scinet.usda.gov/image/rest/services/CWR/test_ex_situ_geo_gaps/ImageServer";
const INGAPS_IMG_LAYER =
  "https://pdi.scinet.usda.gov/image/rest/services/CWR/test_in_situ_geo_gaps/ImageServer";
const EXCOLLECT_IMG_LAYER =
  "https://pdi.scinet.usda.gov/image/rest/services/CWR/test_ex_situ_collections/ImageServer";
const DIST_IMG_LAYER =
  "https://pdi.scinet.usda.gov/image/rest/services/CWR/test_distribution/ImageServer";
const ECOIN_IMG_LAYER =
  "https://pdi.scinet.usda.gov/image/rest/services/CWR/test_in_situ_eco_gaps/ImageServer";
const ECOEX_IMG_LAYER =
  "https://pdi.scinet.usda.gov/image/rest/services/CWR/test_ex_situ_eco_gaps/ImageServer";
const occurenceLayer =
  "https://pdi.scinet.usda.gov/hosting/rest/services/Occurrence_Points_Merged/MapServer/68";


const recordTypeExp = `
var recType = $feature.Record_type;
return Decode(recType, 'G', 'Ex situ conservation occurrence (G)', 'H', 'Reference occurrence (H)', '');
`;

const recordType = {
  name: "record-type",
  title: "Record Type Conversion",
  expression: recordTypeExp,
};

const renderer = {
  type: "unique-value",
  valueExpression: recordTypeExp,
  uniqueValueInfos: [
    {
      value: "Ex situ conservation occurrence (G)",
      symbol: {
        type: "simple-marker",
        color: "#9900ff",
        size: "10px",
        style: "circle",
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: "black",
          width: 2, // points
        },
      },
    },
    {
      value: "Reference occurrence (H)",
      symbol: {
        type: "simple-marker",
        color: "#bfbfbf",
        size: "10px",
        style: "circle",
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: "black",
          width: 1, // points
        },
      },
    },
  ],
};

export const createTaxonLayer = function (species, action) {
  let template = {
    // autocasts as new PopupTemplate()
    title: `{Taxon}`,
    content: [
      {
        type: "text",
        text: `<p>
            <strong>Latitude:</strong> {Latitude}<br/>
            <strong>Longitude:</strong> {Longitude}<br/>
            <strong>Database Source:</strong> {Database_source}<br/>
            <strong>Institution Code:</strong> {Institution_code}<br/>
            <strong>Record type:</strong> {expression/record-type}<br/>
            <strong>Record ID:</strong> {uniqueID}<br/>
            <strong>Locality:</strong> {Locality}<br/>
            </p>`,
      },
    ],
    actions: [action],
    expressionInfos: [recordType],
    collapseEnabled: false,
  };

  const layer = new FeatureLayer({
    url: occurenceLayer,
    title: "Occurrence Points",
    visible: false,
    definitionExpression: `Taxon  = '${species.Taxon}'`,
    popupTemplate: template,
    renderer: renderer,
    orderBy: [
      {
        field: "Record_type",
        order: "descending",
      },
    ],
  });

  return layer;
};

export const createPopupAction = function (species) {
  return {
    // This text is displayed as a tooltip
    title: "More Info",
    // The ID by which to reference the action in the event handler
    id: "more-info",
    // Sets the icon font used to style the action button
    className: "esri-icon-review",
    data: species,
  };
};

export const createTaxonPopup = function (species, action) {
  let template = {
    // autocasts as new PopupTemplate()
    title: `${species.Taxon}`,
    content: `<p>
    <strong>Category:</strong> ${getCategoryPrettyName(species.Category)}
    <br/>
    <strong>Crop Type:</strong> ${
      species["Associated crop type general"] +
      "-" +
      species["Associated crop type specific"]
    }<br/>
    <strong>Crop:</strong> ${species["Associated crop common name"]}<br/>
    <strong>Final conservation score ex situ:</strong> ${getNumStringConcat({
      rankValue: species["FCSex"],
      catValue: species["FCSex priority category"],
    })}<br/>
    <strong>Final conservation score in situ:</strong> ${getNumStringConcat({
      rankValue: species["FCSin"],
      catValue: species["FCSin priority category"],
    })}<br/>
    <strong>Final conservation score combined:</strong> ${getNumStringConcat({
      rankValue: species["FCSc mean"],
      catValue: species["FCSc mean priority category"],
    })}<br/>
    </p>`,
    actions: [action],
  };

  return template;
};

export const createEcoExLayer = (qSpecName, template) =>
  new ImageryLayer({
    url: ECOEX_IMG_LAYER,
    title: "Ex situ eco. gaps",
    popupTemplate: template,
    definitionExpression: `name like '%${qSpecName}_ex_eco_gaps'`,
    visible: false,
    pixelFilter: colorize,
  });

export const createEcoInLayer = (qSpecName, template) =>
  new ImageryLayer({
    url: ECOIN_IMG_LAYER,
    title: "In situ eco. gaps",
    popupTemplate: template,
    definitionExpression: `name like '%${qSpecName}_in_eco_gaps'`,
    visible: false,
    pixelFilter: colorize,
  });

export const createExGapLayer = (qSpecName, template) => {
  let lyr = new ImageryLayer({
    url: EXGAPS_IMG_LAYER,
    title: "Ex situ geo. gaps",
    popupTemplate: template,
    definitionExpression: `name like '%${qSpecName}_ex_geo_gaps'`,
    visible: false,
  });
  return lyr;
};

export const createInGapLayer = (qSpecName, template) => {
  let lyr = new ImageryLayer({
    url: INGAPS_IMG_LAYER,
    title: "In situ geo. gaps",
    popupTemplate: template,
    definitionExpression: `name like '%${qSpecName}_in_geo_gaps'`,
    visible: false,
  });
  return lyr;
};

export const createExCollLayer = (qSpecName, template) => {
  let lyr = new ImageryLayer({
    url: EXCOLLECT_IMG_LAYER,
    title: "Ex situ collections",
    popupTemplate: template,
    definitionExpression: `name like '%${qSpecName}_ex_coll'`,
    visible: false,
  });
  return lyr;
};

export const createDistroLayer = (qSpecName, layerList, template, callback) => {
  let lyr = new ImageryLayer({
    url: DIST_IMG_LAYER,
    title: "Potential Distribution",
    popupTemplate: template,
    definitionExpression: `name like '%${qSpecName}_distr'`,
    visible: true,
  });
  layerList.push(lyr);
  const query = new Query({
    where: `name like '%${qSpecName}_distr'`,
    returnGeometry: true,
  });
  lyr.queryRasters(query).then(callback);
  return lyr;
};

export const addSpeciesLayers = (
  species,
  layerList,
  groupLayer,
  extentCallback
) => {
  let qSpecName = species.Taxon.replaceAll(" ", "_").replaceAll(".", "");

  let action = createPopupAction(species);
  let template = createTaxonPopup(species, action);
  // Only add extent callback for the distro layers,
  let dist = createDistroLayer(qSpecName, layerList, template, extentCallback);

  let coll = createExCollLayer(qSpecName, template);
  coll.queryRasterCount().then((num) => {
    if (num <= 0) {
      groupLayer.remove(coll);
    }
  });
  let ingap = createInGapLayer(qSpecName, template);
  ingap.queryRasterCount().then((num) => {
    if (num <= 0) {
      groupLayer.remove(ingap);
    }
  });
  let exgap = createExGapLayer(qSpecName, template);
  exgap.queryRasterCount().then((num) => {
    if (num <= 0) {
      groupLayer.remove(exgap);
    }
  });
  let ineco = createEcoInLayer(qSpecName, template);
  ineco.queryRasterCount().then((num) => {
    if (num <= 0) {
      groupLayer.remove(ineco);
    }
  });
  let execo = createEcoExLayer(qSpecName, template);
  execo.queryRasterCount().then((num) => {
    if (num <= 0) {
      groupLayer.remove(execo);
    }
  });

  let occurenceLayer = createTaxonLayer(species, action);
  occurenceLayer.queryFeatureCount().then((num) => {
    if (num > 0) {
      groupLayer.add(occurenceLayer);
    }
  });
  groupLayer.add(dist);
  groupLayer.add(coll);
  groupLayer.add(ingap);
  groupLayer.add(exgap);
  groupLayer.add(ineco);
  groupLayer.add(execo);
  //groupLayer.add(occurenceLayer);
};
