const images = [
  {
    src: require("@/assets/img/slideshow/Asimina_triloba.jpg"),
    alt: "Asimina triloba (pawpaw)",
  },
  {
    src: require("@/assets/img/slideshow/Carya_illinoinensis.jpg"),
    alt: "Carya illinoinensis (pecan)",
  },
  {
    src: require("@/assets/img/slideshow/Helianthus_simulans.jpg"),
    alt: "Helianthus simulans (muck sunflower)",
  },
  {
    src: require("@/assets/img/slideshow/Phaseolus_polystachios.jpg"),
    alt: "Phaseolus polystachios (thicket bean)",
  },
  {
    src: require("@/assets/img/slideshow/Prunus_angustifolia.jpg"),
    alt: "Prunus angustifolia (chicksaw plum)",
  },
  {
    src: require("@/assets/img/slideshow/Vaccinium_oxycoccos.jpg"),
    alt: "Vaccinium oxycoccos (small cranberry)",
  },
  {
    src: require("@/assets/img/slideshow/Vaccinium_parvifolium.jpg"),
    alt: "Vaccinium parvifolium (red huckleberry)",
  },
];

export default images;
