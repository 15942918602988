import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";

import { saveAs } from "file-saver";
import JSZip from "jszip";

import CONSTANTS from "../../configs/constants";

export function submitData(formState, appState) {
  pushToFtrLayer({
    ...transformFormData(formState),
    State: transformAppData(appState),
  });
}

function transformFormData(formState) {
  return {
    FirstName: formState.fname,
    LastName: formState.lname,
    Organization: formState.org,
    Email: formState.email,
    Reference: formState.refChoice,
    PurpChoice: formState.purpChoice,
    Other: formState.otherPurp,
  };
}

function transformAppData(appState) {
  return JSON.stringify({
    geographyFilters: transformFilters(appState.geoFilter, "name"),
    conservationFilters: transformFilters(appState.consFilter, "name"),
    taxaFilters: transformFilters(appState.taxFilter, "text"),
    mappedTaxa: appState.mapTaxon.map((o) => o.Taxon),
  });
}

function transformFilters(filters, filterKey) {
  let newFilters = {};

  for (let [key, val] of Object.entries(filters)) {
    if (!val) {
      newFilters[key] = null;
      continue;
    }

    try {
      newFilters[key] = val.map((o) => o[filterKey]);
    } catch {
      newFilters[key] = val[filterKey];
    }
  }

  return newFilters;
}

async function pushToFtrLayer(data) {
  let featureLayer = new FeatureLayer({
    portalItem: { id: "fd9a861fbccd44978396aed6a2388257" },
  });
  let graphic = new Graphic({ attributes: data });

  return featureLayer.applyEdits({ addFeatures: [graphic] });
}

export function exportZip(headers, data) {
  let zip = new JSZip();
  zip.file("info.txt", generateInfoText());
  zip.file("data.csv", generateCsvData(headers, data));
  zip.generateAsync({ type: "blob" }).then((zip) => {
    saveAs(zip, "CWR_Data.zip");
  });
}

function generateInfoText() {
  return (
    `Data Version: ${CONSTANTS.DATA_DISPLAY_VERSION}\n` +
    `Download Date: ${new Date().toISOString()}\n` +
    "Citation: Khoury CK, Carver D, Greene SL, Williams KA, Achicanoy HA, Schori M, León B, " +
    "Wiersema JH, and Frances A (2020) Crop wild relatives of the United States require urgent " +
    "conservation action. Proceedings of the National Academy of Sciences of the United States " +
    "of America 117(52): 33351-33357."
  );
}

function generateCsvData(headers, data) {
  let rows = `${headers.map((o) => o.header).join(",")}\n`;

  for (let row of data) {
    rows += `${headers.map((header) => row[header.header]).join(",")}\n`;
  }

  return rows;
}
