<template>
  <form>
    <div class="flex flex-column">
      <div class="flex-none">
        <h4>Geography Filters:</h4>
      </div>
      <div class="field flex-none">
        <label>Country</label>
        <Dropdown
          v-model="selCountry"
          :options="countries"
          optionLabel="name"
          :filter="true"
          placeholder="Select a Country"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="selectCountry($event)"
          class="w-full"
        />
      </div>
      <div class="field flex-none">
        <label>State/Province</label>
        <Dropdown
          v-model="selState"
          :options="states"
          :disabled="!states"
          optionLabel="name"
          :filter="true"
          placeholder="Select State/Province"
          :showClear="true"
          @change="selectState($event)"
          class="w-full"
        />
      </div>
      <div class="field flex-none">
        <label>County</label>
        <MultiSelect
          v-model="selCounty"
          :disabled="!counties"
          :options="counties"
          optionLabel="name"
          :filter="true"
          placeholder="Select County"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="updateFilterState"
          class="w-full"
        />
      </div>
      <div class="flex-none">
        <Button
          label="Clear Geography Filters"
          icon="pi pi-filter-slash"
          iconPos="left"
          class="p-button-secondary w-full border-1 transition-none"
          aria-label="reset geofilters"
          aria:haspopup="false"
          @click="reset"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";

import stateJson from "@/assets/states.json";
import countyJson from "@/assets/counties.json";
export default {
  name: "GeoFilterContent",
  components: {
    Button,
    Dropdown,
    MultiSelect,
  },
  setup() {
    const store = useStore();

    const selCountry = ref();
    const countries = ref([
      { name: "Canada", code: "CA" },
      { name: "Mexico", code: "MX" },
      { name: "United States", code: "US" },
    ]);

    const selState = ref();
    const states = ref();

    const selCounty = ref();
    const counties = ref();

    onMounted(() => {
      var fltrStore = store.state.geoFilter;
      selCountry.value = fltrStore.country;
      selectCountry(selCountry, false);
      selState.value = fltrStore.state;
      selectState(selState, false);
      selCounty.value = fltrStore.counties;
    });

    function selectCountry(event, emit = true) {
      states.value = null;
      counties.value = null;
      if (event.value) {
        states.value = stateJson.find((o) => o.code == event.value.code)[
          "subdivisions"
        ];
      }
      if (emit) {
        updateFilterState();
      }
    }

    function selectState(event, emit = true) {
      counties.value = null;
      if (event.value) {
        let countyVals = countyJson.find(
          (o) =>
            o["Country"] == selCountry.value.name &&
            o["State/Prov"] == event.value.name
        );
        counties.value = countyVals["Counties"].map((o) => ({
          name: o.County,
          code: o.County,
        }));
      }
      if (emit) {
        updateFilterState();
      }
    }

    function reset() {
      selCountry.value = null;
      selState.value = null;
      states.value = null;
      selCounty.value = null;
      counties.value = null;
      updateFilterState();
    }

    function updateFilterState() {
      var updatedFilters = {
        country: selCountry.value,
        state: selState.value,
        counties: selCounty.value,
      };
      store.commit("updateGeoFilter", updatedFilters);
    }
    return {
      selCountry,
      countries,
      selState,
      states,
      selCounty,
      counties,
      selectCountry,
      selectState,
      reset,
      updateFilterState,
    };
  },
};
</script>

<style scoped></style>
