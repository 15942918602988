import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Expand from "@arcgis/core/widgets/Expand";
import LayerList from "@arcgis/core/widgets/LayerList";
import Basemap from "@arcgis/core/Basemap";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";

export const BaseMaps = [
  Basemap.fromId("gray-vector"),
  Basemap.fromId("dark-gray-vector"),
  Basemap.fromId("hybrid"),
  Basemap.fromId("topo-vector"),
];

const ValidLayers = ["Richness Map", "Occurrence Points"];

export const setMapWidgets = function (mapview, width) {
  const basemapGallery = new BasemapGallery({
    view: mapview,
    source: BaseMaps,
    container: document.createElement("div"),
  });

  const bgExpand = new Expand({
    view: mapview,
    content: basemapGallery,
    group: "right",
    label: "Basemap Gallery",
    expandTooltip: "Expand Basemap Gallery",
    collapseTooltip: "Collapse Basemap Gallery",
  });

  const layerlist = new LayerList({
    view: mapview,
    listItemCreatedFunction: (event) => {
      const item = event.item;
      if (item.layer.type != "group") {
        if (ValidLayers.includes(item.title)) {
          // console.log(item.layer.renderer);
          // // don't show legend twice

          item.panel = {
            content: "legend",
            open: true,
          };
        }
      }
    },
  });

  const layerExpand = new Expand({
    view: mapview,
    content: layerlist,
    group: "right",
    expanded: width >= 700,
    label: "Layer List",
    expandTooltip: "Expand Layer List",
    collapseTooltip: "Collapse Layer List",
  });

  // const legend = new Legend({
  //   view: mapview,
  // });

  // const legendExpand = new Expand({
  //   view: mapview,
  //   content: legend,
  //   group: "right",
  //   label: "Legend",
  //   expandTooltip: "Expand Legend",
  //   collapseTooltip: "Collapse Legend",
  // });
 //
  mapview.ui.add([bgExpand, layerExpand], "top-right");

  const scaleBar = new ScaleBar({
    view: mapview,
  });

  mapview.ui.add(scaleBar, "bottom-left");

  return {
    basemap: basemapGallery,
    layers: layerExpand,
    scale: scaleBar,
  };
};
