<template>
  <form>
    <div class="flex flex-column">
      <div class="flex-none">
        <h4>Taxonomy Filters</h4>
      </div>
      <div class="field flex-none">
        <label id="genuslabel">Genus</label>
        <MultiSelect
          ref="genusref"
          aria-label="genus filter"
          ariaLabelledBy="genuslabel"
          v-model="genusFilters"
          :options="genusData"
          optionLabel="text"
          placeholder="Select Genus"
          :maxSelectedLabels="1"
          @change="emitUpdate"
          tabindex="0"
          class="w-full"
          :filter="true"
        />
      </div>
      <Divider class="m-0 p-0 mt-1 mb-1" />
      <div class="field flex-none">
        <label id="croplabel">Associated Crop</label>
        <MultiSelect
          ref="crop"
          v-model="cropFilters"
          :options="cropData"
          optionLabel="text"
          placeholder="Select Associated Crop"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="emitUpdate"
          class="w-full"
          :filter="true"
        />
      </div>
      <Divider class="m-0 p-0 mt-1 mb-1" />

      <div class="field flex-none">
        <label>Associated Crop Types</label>
        <MultiSelect
          ref="type"
          v-model="typeFilters"
          :options="typeData"
          optionLabel="text"
          placeholder="Select Associated Crop Type"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="emitUpdate"
          class="w-full"
          :filter="true"
        />
      </div>
      <Divider class="m-0 p-0 mt-1 mb-1" />
      <div class="field flex-none">
        <label>Category</label>
        <MultiSelect
          ref="category"
          v-model="catFilters"
          :options="catData"
          optionLabel="text"
          placeholder="Select Category"
          :showClear="true"
          :maxSelectedLabels="1"
          @change="emitUpdate"
          class="w-full"
          :filter="true"
        />
      </div>
      <div class="flex-none">
        <Button
          label="Clear Taxon Filters"
          icon="pi pi-filter-slash"
          iconPos="left"
          class="p-button-secondary w-full border-1 transition-none"
          aria-label="reset geofilters"
          aria:haspopup="false"
          @click="reset"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import MultiSelect from "primevue/multiselect";
import Divider from "primevue/divider";
import Button from "primevue/button";

import json from "@/assets/cwr.json";
import ct from "@/assets/croptypes.json";
import crops from "@/assets/crop.json";
import genus from "@/assets/genus.json";

export default {
  name: "TaxFilterContent",
  components: { MultiSelect, Divider, Button },
  setup() {
    const store = useStore();

    const genusref = ref();
    const category = ref();
    const type = ref();
    const crop = ref();

    const baseData = json;

    const genusFilters = ref();
    const genusData = ref(
      genus.map((o) => ({
        text: o,
        code: o,
      }))
    );

    const cropFilters = ref();
    const cropData = ref(
      crops.map((o) => ({
        text: o,
        code: o,
      }))
    );

    const typeFilters = ref();
    const typeData = ref(ct);

    const catFilters = ref();
    const catData = ref([
      { text: "Close Relative (Cat 1A)", code: "1A" },
      { text: "Distant Relative (Cat 1B)", code: "1B" },
      { text: "Unknown Relative (Cat 1C)", code: "1C" },
    ]);

    onMounted(() => {
      var fltrStore = store.state.taxFilter;
      genusFilters.value = fltrStore.genus;
      cropFilters.value = fltrStore.crop;
      typeFilters.value = fltrStore.type;
      catFilters.value = fltrStore.category;
    });

    function reset() {
      genusFilters.value = null;
      cropFilters.value = null;
      typeFilters.value = null;
      catFilters.value = null;
      emitUpdate();
    }

    function emitUpdate() {
      var updatedFilters = {
        genus: genusFilters.value,
        crop: cropFilters.value,
        type: typeFilters.value,
        category: catFilters.value,
      };
      store.commit("updateTaxFilter", updatedFilters);
    }
    return {
      genusref,
      category,
      type,
      crop,
      genusFilters,
      genusData,
      cropFilters,
      cropData,
      typeFilters,
      typeData,
      catFilters,
      catData,
      emitUpdate,
      reset,
    };
  },
};
</script>

<style scoped></style>
