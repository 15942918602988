<template>
  <div id="viewDiv" />
  <div id="version" class="text-lg font-bold mr-2 pr-1 pl-1">
    Data Version: {{ CONSTANTS.DATA_DISPLAY_VERSION }}
  </div>
  <div id="species-view" class="view-button">
    <SpeciesTable />
  </div>

  <Dialog
    v-if="mounted"
    :header="dialogHeader"
    v-model:visible="popupDisplay"
    :breakpoints="{ '960px': '100vw' }"
    :style="{ width: '60vw' }"
    :modal="true"
    appendTo="#viewDiv"
    @hide="resetDialog"
    :dismissableMask="true"
  >
    <DetailedSpecies :Species="speciesInfo" />
    <template #footer>
      <Button label="Done" icon="pi pi-check" @click="closeDialog" />
    </template>
  </Dialog>
  <Dialog :visible="loading" :draggable="false" :closable="false">
    <ProgressSpinner class="spinner" stroke-width="3" animation-duration="3s" />
    <br />
    Loading map data. Please wait...
  </Dialog>
</template>

<script>
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { when } from "@arcgis/core/core/reactiveUtils";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import { onMounted, watch, ref } from "vue";
import { useWindowSize } from "vue-window-size";
import { useStore } from "vuex";

import CONSTANTS from "../../configs/constants.js";
import DetailedSpecies from "../Species/DetailedSpecies.vue";
import SpeciesTable from "../Species/SpeciesTable.vue";
import {
  STATE_LAYER,
  COUNTY_LAYER,
  RICHNESS_LAYER,
  ECOREGION_LAYER,
  PROTAREA_LAYER,
} from "./layers.js";
import { DEFAULT_POINT, ADMIN_HIGHLIGHT_LINE } from "./mapConst.js";
import { setMapWidgets, BaseMaps } from "./mapWidgets.js";
import { addSpeciesLayers } from "./taxonLayer.js";

export default {
  name: "CWRMap",
  components: {
    ProgressSpinner,
    SpeciesTable,
    DetailedSpecies,
    Button,
    Dialog,
  },
  setup() {
    const { width, height } = useWindowSize();

    const store = useStore();

    const mounted = ref(false);

    const speciesInfo = ref();
    const popupDisplay = ref(false);
    const dialogHeader = ref("Detailed Species Information");
    const newSelection = ref(false);

    function resetDialog() {
      speciesInfo.value = null;
    }

    function closeDialog() {
      popupDisplay.value = false;
    }

    // Content Sizing Section
    const mapSize = ref("100%");
    const viewBtnSize = ref("0%");

    var smallScreen = width.value <= 960;
    var mapped = false;

    // Loading section
    var loading = ref(false);

    function setLayoutSizes() {
      if (mapped) {
        if (loading.value) {
          mapSize.value = "95%";
          viewBtnSize.value = "5%";
        } else {
          mapSize.value = "95%";
          viewBtnSize.value = "5%";
        }
      } else {
        mapSize.value = "100%";
        viewBtnSize.value = "0%";
      }
    }

    // Map content
    const map = new Map({
      basemap: BaseMaps[0],
    });

    var mv;
    var layers = [];
    var rasterFeatures = [];
    var gls = [];

    var widgets;

    function setMainLayers() {
      map.add(STATE_LAYER);
      map.add(COUNTY_LAYER);
      map.add(RICHNESS_LAYER);
      map.add(ECOREGION_LAYER);
      map.add(PROTAREA_LAYER);
    }

    function setupMapView() {
      mv = new MapView({
        container: "viewDiv",
        map: map,
        zoom: 3,
        center: DEFAULT_POINT,
        snapToZoom: false,
      });
      mv.ui.add(document.getElementById("version"), "bottom-leading");
      mv.constraints.rotationEnabled = false;

      when(
        () => mv.updating && newSelection.value,
        () => {
          loading.value = true;
        }
      );

      when(
        () => !(mv.updating && newSelection.value),
        () => {
          loading.value = false;
          newSelection.value = false;
        }
      );

      widgets = setMapWidgets(mv, width.value);

      mv.popup.on("trigger-action", function (event) {
        if (event.action.id === "more-info") {
          speciesInfo.value = event.action.data;
          dialogHeader.value = `Detailed Species Info - ${event.action.data.Taxon}`;
          popupDisplay.value = true;
        }
      });
      mv.popup.collapseEnabled = false;
      setMainLayers();
    }

    function setExtent() {
      let extent = null;
      for (var raster of rasterFeatures) {
        if (!extent) {
          extent = raster.geometry.extent;
        } else {
          extent = extent.union(raster.geometry.extent);
        }
      }
      mv.goTo(extent);
      setLayoutSizes();
    }

    function setMapToBase() {
      mv.goTo(
        {
          target: DEFAULT_POINT,
          zoom: 3,
        },
        { duration: 1000 }
      );
    }

    function updateLayers() {
      let specVals = store.state.mapTaxon;
      newSelection.value = true;

      if (specVals.length == 0) {
        map.removeAll();
        setMainLayers();
        layers = gls = rasterFeatures = [];
        setMapToBase();
        mapped = false;
        setLayoutSizes();
      } else {
        // reset map on "map" action
        map.removeMany(gls);
        gls = [];
        layers = [];
        rasterFeatures = [];
        var loaded = 0;
        mapped = true;
        setLayoutSizes();

        for (const spec of specVals) {
          var gl = new GroupLayer({
            title: spec.Taxon,
          });

          let callBack = (featureset) => {
            loaded++;
            rasterFeatures = rasterFeatures.concat(featureset.features);

            if (loaded == layers.length) {
              setExtent();
            }
          };
          // exported to static functions
          addSpeciesLayers(spec, layers, gl, callBack);
          gls.push(gl);
          map.add(gl);
        }
      }
    }

    onMounted(() => {
      setupMapView();
      mounted.value = true;

      //consolattribution.viewModel
    });

    var highlightedGraphics = [];

    function highlightFeatures(features) {
      highlightedGraphics = features.map((o) => o.clone());
      highlightedGraphics.forEach((o) => {
        o.symbol = ADMIN_HIGHLIGHT_LINE;
        mv.graphics.add(o);
      });
      mv.goTo(highlightedGraphics);
    }

    async function queryAdminFeatures(layer, where, outFields) {
      const query = layer.createQuery();
      query.where = where;
      query.outFields = outFields;
      return await layer.queryFeatures(query);
    }

    async function highlightGeo() {
      var flt = store.state.geoFilter;
      if (highlightedGraphics.length > 0) {
        mv.graphics.removeAll(highlightedGraphics);
        highlightedGraphics = [];
      }
      try {
        if (flt.counties?.length > 0) {
          let where = `NAME_1 ='${
            flt.state.name
          }' and NAME_2 in ('${flt.counties.map((o) => o.name).join("','")}')`;
          let outFields = ["OBJECTID", "NAME_1", "NAME_2"];
          const res = await queryAdminFeatures(COUNTY_LAYER, where, outFields);
          highlightFeatures(res.features);
        } else if (flt.state) {
          let where = `NAME_1 ='${flt.state.name}'`;
          let outFields = ["OBJECTID", "NAME_1"];
          const res = await queryAdminFeatures(STATE_LAYER, where, outFields);
          highlightFeatures(res.features);
        } else {
          // no filters, set to base:
          setMapToBase();
        }
      } catch (error) {
        console.log(error);
      }
    }

    watch(() => store.state.mapTaxon, updateLayers);

    watch(() => store.state.geoFilter, highlightGeo);

    function manageWidgetsWidthChange() {
      if (widgets) {
        widgets.layers.expanded = width.value >= 698;
      }
      smallScreen = width.value <= 960;
      setLayoutSizes();
    }

    watch(width, manageWidgetsWidthChange);

    return {
      mounted,
      popupDisplay,
      dialogHeader,
      speciesInfo,
      resetDialog,
      closeDialog,
      loading,
      updateLayers,
      mapSize,
      viewBtnSize,
      CONSTANTS,
    };
  },
};
</script>
<style scoped>
@import "~@arcgis/core/assets/esri/themes/light/main.css";

.loading-bar {
  height: v-bind(loadingSize);
}

.spinner {
  height: 8vh;
  width: 8vh;
  display: block;
  margin: auto;
}

.view-button {
  height: v-bind(viewBtnSize);
}

@media screen and (max-width: 960px) {
  .view-button {
    display: none !important;
  }
}

#viewDiv {
  padding: 0;
  margin: 0;
  height: v-bind(mapSize);
  width: 100%;
}

#version {
  background-color: #f3f3f3;
}
</style>
