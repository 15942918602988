<template>
  <Dialog
    :show-header="false"
    v-model:visible="visible"
    :closable="false"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    style="width: 30vw"
  >
    <h2>Please out this form before you export the requested data</h2>
    <div class="field">
      <label for="fname">First Name</label>
      <br />
      <InputText class="w-full" id="fname" type="text" v-model="fname" />
    </div>
    <div class="field">
      <label for="lname">Last Name</label>
      <br />
      <InputText class="w-full" id="lname" type="text" v-model="lname" />
    </div>
    <div class="field">
      <label for="org">Organization</label>
      <br />
      <InputText class="w-full" id="org" type="text" v-model="org" />
    </div>
    <div class="field">
      <label for="email">Email <span class="required">*</span></label
      ><br />
      <InputText
        id="email"
        v-model="v$.email.$model"
        class="w-full"
        :class="{ 'p-invalid': v$.email.$invalid }"
        aria-describedby="email-error"
      />
      <div class="field"></div>
      <label>How You Found Us</label>
      <Dropdown
        v-model="refChoice"
        placeholder="Select How You Found Us"
        class="w-full"
        :options="[
          { key: 'Publication or Other Source' },
          { key: 'Colleague or Friend' },
          { key: 'No Prior Knowledge' },
        ]"
        optionLabel="key"
        optionValue="key"
      />
      <div class="field"></div>
      <label>Intended Use <span class="required">*</span></label>
      <Dropdown
        v-model="purpChoice"
        placeholder="Select Intended Use"
        class="w-full"
        :class="{ 'p-invalid': !purpChoice }"
        :options="[
          { key: 'Plant Breeding' },
          { key: 'Germplasm collection' },
          { key: 'In situ protection' },
          { key: 'Research' },
          { key: 'Education/Outreach' },
          { key: 'Other' },
        ]"
        optionLabel="key"
        optionValue="key"
      />
      <div class="field"></div>
      <div v-if="purpChoice === 'Other'" class="field">
        <label for="otherPurp">Other Purpose</label>
        <br />
        <InputText
          class="w-full"
          id="otherPurp"
          type="text"
          v-model="otherPurp"
        />
      </div>
    </div>
    <br />
    <div class="flex justify-content-end">
      <Button
        class="w-full"
        icon="pi pi-check"
        label="Submit"
        :disabled="v$.email.$invalid && !purpChoice"
        @click="submit"
      />
      <Button
        icon="pi pi-times"
        label="Cancel"
        class="p-button-secondary w-full"
        style="margin-left: 0.5em"
        @click="cancel"
      />
    </div>
  </Dialog>
</template>

<script>
import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";

import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";

import { submitData } from "./exportLogic";
import { event } from "vue-gtag";

export default {
  name: "DownloadForm",
  components: {
    Button,
    Dialog,
    Dropdown,
    InputText,
  },
  props: {
    formVisible: Boolean,
  },
  setup(props) {
    const store = useStore();
    const visible = ref(props.formVisible);

    const formState = reactive({
      fname: "",
      lname: "",
      org: "",
      email: "",
      refChoice: "",
      purpChoice: "",
      otherPurp: "",
    });

    const rules = {
      email: { required, email },
    };

    const v$ = useVuelidate(rules, formState);

    const fname = ref();
    const lname = ref();
    const org = ref();
    const refChoice = ref();
    const purpChoice = ref();
    const otherPurp = ref();

    function submit() {
      formState.fname = fname.value;
      formState.lname = lname.value;
      formState.org = org.value;
      formState.refChoice = refChoice.value;
      formState.purpChoice = purpChoice.value;
      formState.otherPurp = otherPurp.value;

      submitData(formState, {
        geoFilter: store.state.geoFilter,
        consFilter: store.state.consFilter,
        taxFilter: store.state.taxFilter,
        mapTaxon: store.state.mapTaxon,
      });

      store.commit("toggleDlFormSubmitted");
      toggle();
    }

    function cancel() {
      event("cancel");
      toggle();
    }

    function toggle() {
      visible.value = !visible.value;
    }

    watch(() => props.formVisible, toggle);

    return {
      visible,
      formState,
      fname,
      lname,
      org,
      refChoice,
      purpChoice,
      otherPurp,
      v$,
      submit,
      cancel,
      toggle,
    };
  },
};
</script>

<style>
.required {
  color: red;
}
</style>
