<template>
  <div class="green-panel">
    <div class="small-menu w-full p-2">
      <Button
        label="Filters"
        @click="toggleSmallMenu"
        :icon="`pi ${menuPanel == 'none' ? 'pi-filter' : 'pi-times'}`"
        class="p-button-text p-button-plain font-bold toolbar-button transition-none filter-button"
        :badge="`${badgeCounter == 0 ? '' : '+' + badgeCounter.toString()}`"
        badgeClass="p-badge"
      />
    </div>
    <div class="menu-panel">
      <div class="menu-container h-full w-full p-2">
        <div class="filter-item">
          <FilterWrapper
            ref="tax"
            :appendTo="'#txFilterSpace'"
            :buttonTitle="'Taxonomy Filter'"
            :panelAriaCloseLabel="'close taxonomy filter panel'"
            @clicked="clicked('tax')"
          >
            <TaxFilterContent />
          </FilterWrapper>
          <div id="txFilterSpace" />
        </div>
        <div class="divider">
          <Divider layout="vertical" />
        </div>
        <div class="filter-item">
          <FilterWrapper
            ref="cons"
            :appendTo="'#consFilterSpace'"
            :buttonTitle="'Conservation Priority Filter'"
            :panelAriaCloseLabel="'close conservation priority filter panel'"
            @clicked="clicked('cons')"
          >
            <ConsFilterContent />
          </FilterWrapper>
          <div id="consFilterSpace" />
        </div>
        <div class="divider">
          <Divider layout="vertical" />
        </div>
        <div class="filter-item">
          <FilterWrapper
            ref="geo"
            :appendTo="'#geoFilterSpace'"
            :buttonTitle="'Geography Filter'"
            :panelAriaCloseLabel="'close geography filters'"
            @clicked="clicked('geo')"
          >
            <GeoFilterContent />
          </FilterWrapper>
          <div id="geoFilterSpace" />
        </div>
        <div id="view-res">
          <Button
            id="filter-view-btn"
            label="View Results"
            @click="toggleSmallMenu"
            icon="pi pi-filter"
            class="p-button-info w-full h-full font-bold transition-none"
          />
        </div>
        <div class="buttons">
          <a
            href="https://pdi.scinet.usda.gov/pdiimg/pdi/CWR_Howto02_Made_with_Clipchamp.mp4"
            target="_blank"
          >
            <Button
              label="How to Use"
              icon="pi pi-question-circle"
              class="p-button-info font-bold transition-none border-1"
            />
          </a>
          &nbsp;
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=5zZb7e4BvE6GfuA8-g1Gl2HAOVFsTnhFnpLKbPcbLdJUQzE4OENRRE1OUzFGQzFWNlYxOFRaSzJGTi4u"
            target="_blank"
          >
            <Button
              label="User Survey"
              icon="pi pi-external-link"
              class="p-button-info font-bold transition-none border-1"
            />
          </a>
          &nbsp;
          <Button
            label="Reset App"
            @click="reset($event)"
            icon="pi pi-filter-slash"
            class="p-button-secondary font-bold transition-none border-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "primevue/toolbar";
import Divider from "primevue/divider";
import Button from "primevue/button";
import FilterWrapper from "./FilterWrapper.vue";
import ConsFilterContent from "./ConFilterContent.vue";
import TaxFilterContent from "./TaxFilterContent.vue";
import GeoFilterContent from "./GeoFilterContent.vue";

import OverlayPanel from "primevue/overlaypanel";
import { useStore } from "vuex";
import { useWindowSize } from "vue-window-size";

import { ref, toRefs, onMounted, watch } from "vue";

export default {
  name: "FilterMenu",
  components: {
    Divider,
    FilterWrapper,
    TaxFilterContent,
    GeoFilterContent,
    Button,
    ConsFilterContent,
  },
  setup() {
    const { width, height } = useWindowSize();
    const store = useStore();
    const badgeCounter = ref(0);

    const cons = ref();
    const tax = ref();
    const geo = ref();

    const menuPanel = ref("none");

    const lgColor = ref("hsl(360, 100%, 100%)");
    const lgHighlight = ref("hsl(130, 100%, 20%)");

    const smColor = lgHighlight;
    const smHighlight = lgColor;

    const mounted = ref(false);

    onMounted(() => {
      mounted.value = true;
    });

    function reset() {
      store.dispatch("reset");
      if (width.value <= 960) {
        toggleSmallMenu();
      }
    }

    function toggleSmallMenu() {
      if (menuPanel.value === "none") {
        cons.value.hide();
        geo.value.hide();
        tax.value.hide();
      }
      menuPanel.value = menuPanel.value == "none" ? "block" : "none";
    }

    function setCounter(event) {
      var conFilter = store.state.consFilter;
      var taxonFilter = store.state.taxFilter;
      var geoFilter = store.state.geoFilter;

      let consCtr =
        (conFilter.insitu?.length ?? 0) +
        (conFilter.exsitu?.length ?? 0) +
        (conFilter.combined?.length ?? 0);
      let taxCtr =
        (taxonFilter.genus?.length ?? 0) +
        (taxonFilter.crop?.length ?? 0) +
        (taxonFilter.type?.length ?? 0) +
        (taxonFilter.category?.length ?? 0);
      let geoCtr =
        (geoFilter.country ? 1 : 0 ?? 0) +
        (geoFilter.state ? 1 : 0 ?? 0) +
        (geoFilter.counties?.length ?? 0);

      badgeCounter.value = consCtr + taxCtr + geoCtr;
      cons.value.setCounter(consCtr);
      tax.value.setCounter(taxCtr);
      geo.value.setCounter(geoCtr);
    }

    watch(
      () => [
        store.state.geoFilter,
        store.state.consFilter,
        store.state.taxFilter,
      ],
      setCounter
    );

    function clicked(source) {
      switch (source) {
        case "cons":
          geo.value.hide();
          tax.value.hide();
          break;
        case "tax":
          cons.value.hide();
          geo.value.hide();
          break;
        case "geo":
          cons.value.hide();
          tax.value.hide();
          break;
      }
    }

    return {
      cons,
      tax,
      geo,
      badgeCounter,
      menuPanel,
      mounted,
      reset,
      toggleSmallMenu,
      lgColor,
      lgHighlight,
      smColor,
      smHighlight,
      clicked,
    };
  },
};
</script>

<style scoped>
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.menu-container {
  display: flex;
  justify-content: flex-start;
}

.menu-container {
  flex-direction: row;
}

.filter-item {
  flex: 0 1 auto;
}

.divider {
  display: block;
}

.buttons {
  flex: 0 1 auto;
  margin: 0 0 0 auto;
}

.small-menu {
  display: none;
}

#view-res {
  display: none;
}

.green-panel {
  background-color: hsl(130, 100%, 20%);
  box-sizing: border-box;
  border-radius: 0%;
  border: 0px;
}

.toolbar-button.p-button.p-button-text {
  color: hsl(360, 100%, 100%);
}

.toolbar-button.p-button-text.p-button-plain:enabled:hover {
  background: white;
  color: hsl(130, 100%, 20%);
}

@media screen and (max-width: 960px) {
  .menu-panel {
    display: v-bind(menuPanel);
    position: fixed;
    border: none;
    background: white;
    z-index: 900;
    width: 100%;
  }
  .menu-container {
    flex-direction: column;
  }

  .small-menu {
    display: block;
  }

  .filter-item {
    display: block;
    flex: 0 1 auto;
    margin: 0.5rem 0;
  }

  .divider {
    display: none;
  }

  .reset {
    flex: 0 1 auto;
    margin: 0.5rem 0;
  }

  #view-res {
    display: block;
    flex: 0 1 auto;
    margin: 0.5rem 0;
  }

  #filter-reset {
    width: 100%;
    height: 100%;
  }

  #filter-view-btn {
    background-color: var(--blue-800);
  }

  .toolbar-button.p-button.p-button-text {
    background-color: hsl(130, 100%, 20%);
    color: hsl(360, 100%, 100%);
  }
  .toolbar-button.p-button.p-button-text {
    width: 100%;
  }

  .toolbar-button.p-button.p-button-text {
    background-color: hsl(130, 100%, 20%);
    color: hsl(360, 100%, 100%);
  }
  .toolbar-button.p-button.p-button-text.p-button-plain:enabled:hover {
    background: hsl(130, 100%, 20%);
    color: white;
  }

  .p-button.p-button-text.p-button-plain:enabled:hover {
    color: hsl(130, 100%, 20%);
    background: white;
  }
}
</style>
