<template>
  <div
    class="grid align-content-center justify-content-center grid-nogutter m-0"
  >
    <div class="col-12">
      <div class="grid">
        <div class="col-fixed" style="width: 40%">
          <ImageSlideshow :images="images" :interval="5000" />
        </div>
        <div class="col text-justify line-height-3">
          This website offers an interactive exploration of the distributions
          and conservation status of over 600 plants native to the United
          States. These plants are important because they can be used to improve
          food and agricultural crops, since they are their close (wild)
          cousins. This work was published by
          <a
            href="https://www.pnas.org/doi/10.1073/pnas.2007029117"
            target="_blank"
            >Khoury and Carver <i>et al.</i> (2020)</a
          >, building on over a decade of efforts focused on compiling
          occurrence information, developing eco-geographic methods, and
          conducting conservation gap analyses.
          <br />
        </div>
      </div>
      <Divider class="m-0 p-0 mt-1 mb-1" />
      <AboutSection />
    </div>
    <Divider class="m-0 p-0 mt-1 mb-1" />
    <div class="col-12 text-justify">
      <div class="line-height-3 text-center">
        This website was built by the ARS Partnerships for Data Innovation Tech
        Team in collaboration with Colin Khoury, Dan Carver, Stephanie Greene,
        Karen Williams, and Anne Frances.
        <br />
        <br />
        The data contained in this application is
        <strong>Version {{ CONSTANTS.DATA_DISPLAY_VERSION }}</strong
        >.
        <br />
        This work is licensed under a Creative Commons Attribution 4.0
        International (CC BY 4.0).
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import Divider from "primevue/divider";

import CONSTANTS from "../../configs/constants.js";
import AboutSection from "./AboutTheMap/AboutSection.vue";
import ImageSlideshow from "./ImageSlideshow.vue";

export default {
  name: "AboutTheMap",
  components: {
    Divider,
    ImageSlideshow,
    AboutSection,
  },
  setup() {
    return {
      CONSTANTS,
    };
  },
};
</script>
