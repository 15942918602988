<template>
  <div class="flex-none p-0 m-0">
    <Banner
      title="Crop Wild Relatives of the U.S."
      titletip="Crop Wild Relatives of the U.S. Exploration Tool"
      agency="Agricultural Research Service"
    />
  </div>
  <div class="flex-auto p-0 m-0">
    <CWR />
  </div>
  <div class="flex-none p-0 m-0">
    <Menubar :model="footers"> </Menubar>
  </div>
  <About :visible="aboutVisible" />
</template>

<script>
import { Banner } from "@ars-pdi/agcros-site-utils";

import Menubar from "primevue/menubar";

import { ref, onMounted } from "vue";

import { pageview } from "vue-gtag";

import About from "./components/About.vue";
import CWR from "./components/CWR.vue";
import links from "./assets/linkConfig.json";

export default {
  name: "App",
  components: {
    About,
    Banner,
    CWR,
    Menubar,
  },
  setup() {
    const footers = ref(links);
    const aboutVisible = ref(false);

    footers.value.unshift({
      label: "About",
      icon: "pi pi-fw pi-question-circle",
      command: (event) => {
        aboutVisible.value = !aboutVisible.value;
      },
    });
    footers.value.unshift({
      separator: true,
    });
    onMounted(() => {
      pageview("/");
    });
    return {
      footers,
      aboutVisible,
    };
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  /*font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.esri-widget,
.esri-input {
  font-family: inherit !important;
}

#app {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.p-menubar.p-menubar-mobile-active .p-menubar-root-list {
  top: -600% !important;
}
</style>
