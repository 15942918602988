<template>
  <div class="content-size">
    <ul>
      <li class="mt-2 mb-2">
        Khoury CK, Carver D, Greene SL, Williams KA, Achicanoy HA, Schori M,
        León B, Wiersema JH, and Frances A (2020)
        <a href="https://doi.org/10.1073/pnas.2007029117" target="_blank">
          Crop wild relatives of the United States require urgent conservation
          action.
        </a>
        <em> Proc Natl Acad Sci USA</em> 117(52): 33351-33357.
      </li>
      <li class="mt-2 mb-2">
        Khoury CK, Greene SL, Krishnan S, Miller AJ, Moreau T, Williams KA,
        Rodriguez-Bonilla L, Spurrier C, Zalapa J, and Nabhan GP (2020)
        <a href="https://doi.org/10.3375/043.040.0111" target="_blank">
          Toward integrated conservation of North America's crop wild relatives.
        </a>
        <em>Natural Areas Journal</em> 40(1): 96-100.
      </li>
      <li class="mt-2 mb-2">
        Greene SL, Williams KA, Khoury CK, Kantar MB, and Marek LF, editors.
        2019.
        <a href="https://doi.org/10.1007/978-3-319-97121-6" target="_blank">
          <em>
            North American Crop Wild Relatives. Volume 2: Important Species.
          </em>
        </a>
        New York, NY: Springer, Cham. 740 p.
      </li>
      <li class="mt-2 mb-2">
        Khoury CK, Greene SL, Krishnan S, Miller AJ, and Moreau T. 2019.
        <a href="https://doi.org/10.2135/cropsci2019.05.0309" target="_blank">
          A road map for conservation, use, and public engagement around North
          America's crop wild relatives and wild utilized plants.
        </a>
        <em>Crop Science</em> 59(6): 2302-2307.
      </li>
      <li class="mt-2 mb-2">
        Greene SL, Williams KA, Khoury CK, Kantar MB, and Marek LF, editors.
        2018.
        <a href="https://doi.org/10.1007/978-3-319-95101-0" target="_blank">
          <em>
            North American Crop Wild Relatives. Volume 1: Conservation
            Strategies.
          </em>
        </a>
        New York, NY: Springer, Cham. 346 p.
      </li>
      <li class="mt-2 mb-2">
        Khoury CK, Greene S, Wiersema J, Maxted N, Jarvis A, and Struik PC.
        2013.
        <a href="https://doi.org/10.2135/cropsci2012.10.0585" target="_blank">
          An inventory of crop wild relatives of the United States.
        </a>
        <em>Crop Science</em> 53(4): 1496-1508.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Publications",
  setup() {
    return {};
  },
};
</script>

<style></style>
