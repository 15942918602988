<template>
  <h4>
    Examples of spatial data available for species including potential
    distribution, occurrence points (germplasm and reference), conservation gaps
  </h4>
  <p>
    In the examples below, the term <i>in situ</i> (Latin for on site) refers to
    the conservation of plant populations in the wild while the term
    <i>ex situ</i> (Latin for off site) refers to the conservation of germplasm
    in collections such as genebanks and botanical gardens.
  </p>
  <p>
    The potential distributions of species are generalized representations of
    where species are likely to occur and are based on available occurrence data
    and associated environmental variables. Additional data sources may be
    required to validate a species’ occurrence at localities within a potential
    distribution. The suitability of any modeled distribution depends on the
    intended use of the data. For additional details on the methodology used for
    the potential distributions, please review the appendix associated with
    <a
      href="https://www.pnas.org/doi/10.1073/pnas.2007029117#supplementary-materials"
      target="_blank"
      >Khoury and Carver <i>et al.</i> (2020)</a
    >.
  </p>
  <p>
    <b>Potential Distribution</b> of <i>Vitis monticola</i> (green polygons)
    with Richness Map as the background layer. This is the only species layer
    enabled in the legend by default.
    <br />
    <Image :src="require('@/assets/img/about/about_a.png')" />
  </p>
  <p>
    <b>Occurrence Points</b> of <i>Vitis monticola</i> displayed with potential
    distribution (green polygons). Purple circles represent germplasm accessions
    in <i>ex situ</i> repositories and gray circles represent reference
    occurrences, referred to as G (germplasm) and H (herbarium) occurrences,
    respectively.
    <br />
    <Image :src="require('@/assets/img/about/about_b.png')" />
  </p>
  <p>
    <b><i>In situ</i> geo. gaps</b> of <i>Vitis monticola</i> (rose polygons)
    displayed with potential distribution (green polygons). This map displays
    areas of the species’ potential distribution that are lacking protection in
    the wild. Zooming in farther will provide greater visibility of the green
    polygons. <br />
    <Image :src="require('@/assets/img/about/about_c.png')" />
  </p>
  <p>
    <b><i>Ex situ</i> geo. gaps</b> of <i>Vitis monticola</i> (gold polygons)
    displayed with potential distribution (green polygons). This map displays
    areas of the species’ potential distribution that are lacking germplasm
    representation n <i>ex situ</i> repositories. <br />
    <Image :src="require('@/assets/img/about/about_d.png')" />
  </p>
  <p>
    <b><i>In situ</i> eco. gaps</b> of <i>Vitis monticola</i> (red and blue
    polygons) displayed with potential distribution (green polygons). This map
    displays areas within the species’ potential distribution of ecoregions that
    are lacking protection in the wild. <br />
    <Image :src="require('@/assets/img/about/about_e.png')" />
  </p>
  <p>
    <b><i>Ex situ</i> eco. gaps</b> of <i>Vitis monticola</i> (red polygons)
    displayed with potential distribution (green polygons). This map displays
    ecoregions within the species’ potential distribution that are lacking
    representation of germplasm of the species in <i>ex situ</i> repositories.
    <br />
    <Image :src="require('@/assets/img/about/about_f.png')" />
  </p>
  <p>
    <b><i>Ex situ</i> collections</b> (purple circles) displayed with potential
    distribution (green polygons). Each purple circle represents the location of
    a germplasm collection with a 50-km-radius-buffer to estimate geographic
    areas already well collected within a species’ potential distribution.
    <br />
    <Image :src="require('@/assets/img/about/about_g.png')" />
  </p>
</template>

<script>
import Image from "primevue/image";

export default {
  name: "AboutSection",
  components: {
    Image,
  },
};
</script>
