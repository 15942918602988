import { getDecFixedLocale, getNumLocale } from "./numUtils.js";

export const getCategoryPrettyName = function (category) {
  switch (category) {
    case "1A":
      return "Close Relative (Cat 1A)";
    case "1B":
      return "Distant Relative (Cat 1B)";
    case "1C":
      return "Unknown Relative (Cat 1C)";
    default:
      return "N/A";
  }
};

export const getPriorityPrettyName = function (priority) {
  switch (priority) {
    case "UP":
      return "Urgent priority";
    case "HP":
      return "High priority";
    case "MP":
      return "Medium priority";
    case "LP":
      return "Low Priority";
    default:
      return "N/A";
  }
};

export const getUSEndangeredSpecActRank = function (rank) {
  switch (rank) {
    case "LE":
      return "Listed Endangered";
    case "LT":
      return "Listed Threatened";
    default:
      return "Not Listed";
  }
};

export const getPriorityColor = function (priority) {
  switch (priority) {
    case "UP":
      return "urgent-priority";
    case "HP":
      return "high-priority";
    case "MP":
      return "medium-priority";
    case "LP":
      return "low-priority";
    default:
      return "N/A";
  }
};

const convertNatureRank = function (rankValue) {
  switch (rankValue) {
    case "G1":
      return "Critically Imperiled";
    case "G2":
      return "Imperiled";
    case "G3":
      return "Vulnerable";
    case "G4":
      return "Apparently Secure";
    case "G5":
      return "Secure";
  }
};

export const getNatureRank = function (rankValue, year) {
  let res = "";
  if (rankValue){
    res += `${convertNatureRank(rankValue)}`
    res += ` ( ${rankValue}${' - ' + year} )`;
  }
  return res ? res : "Not Evaluated";
};

export const getRedList = function (cat, year) {
  let res = "";
  res += cat ? cat : "";
  res += year ? ` (${year})` : "";
  return res ? res : "Not Evaluated";
};

export const getNumStringConcat = function ({
  rankValue,
  catValue,
  stringValue,
}) {
  return `${getDecFixedLocale(rankValue, 1)} - ${
    catValue ? getPriorityPrettyName(catValue) : stringValue
  }`;
};
