<template>
  <div class="flex flex-column h-full">
    <div class="flex-none">
      <FilterMenu />
    </div>
    <div class="flex-auto">
      <div class="flex flex-direction h-full">
        <div class="flex-spec">
          <Species />
        </div>
        <div class="flex-map align-self-stretch">
          <CWRMap />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CWRMap from "./MapView/CWRMap.vue";
import FilterMenu from "./Filters/FilterMenu.vue";
import Species from "./Species/Species.vue";

export default {
  name: "CWR",
  components: {
    CWRMap,
    Species,
    FilterMenu,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.flex-spec {
  flex: 0 0 20%;
}

.flex-map {
  flex: 0 0 80%;
  overflow: auto;
}
.flex-direction {
  flex-direction: row !important;
}

@media screen and (max-width: 960px) {
  .flex-spec {
    flex: 0 0 5%;
    overflow: auto;
  }

  .flex-map {
    flex: 0 0 95%;
    overflow: auto;
  }

  .flex-direction {
    flex-direction: column !important;
  }
}
</style>
