<template>
  <div class="h-full vert-center" v-if="species.length > 0">
    <Button
      label="View Data"
      class="p-button-secondary h-full border-noround w-full"
      @click="toggle"
      aria-controls="overlay_panel"
      aria:haspopup="true"
    />
  </div>
  <OverlayPanel
    ref="panel"
    appendTo="body"
    :showCloseIcon="true"
    :dismissable="false"
    id="overlay_panel"
    style="width: 75%"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
  >
    <DataTable
      :value="species"
      selectionMode="single"
      :paginator="true"
      :rows="5"
      ref="table"
      class="p-datatable-sm"
      style="width: 100%; height: 100%"
      responsiveLayout="scroll"
      :autoLayout="true"
      exportFilename="CWR_Export"
    >
      <template #header>
        <div style="text-align: left">
          <Button
            class="p-button-success"
            icon="pi pi-external-link"
            label="Export"
            @click="promptDownloadForm()"
          />
        </div>
      </template>
      <Column
        v-for="col of headers"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :exportHeader="col.export"
      ></Column>
    </DataTable>
  </OverlayPanel>
  <DownloadForm :formVisible="showDlForm" />
</template>

<script>
import { watch, ref, computed } from "vue";

import { event } from "vue-gtag";
import { useStore } from "vuex";

import json from "@/assets/headers.json";

import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import OverlayPanel from "primevue/overlaypanel";

import DownloadForm from "./DownloadForm.vue";
import { exportZip } from "./exportLogic";

export default {
  name: "SpeciesTable",
  components: {
    Button,
    Column,
    DataTable,
    DownloadForm,
    OverlayPanel,
  },
  setup() {
    const store = useStore();

    const panel = ref();
    const headers = ref(json.map((h) => ({ header: h, field: h, export: h })));
    const table = ref();
    const showDlForm = ref(false);

    function toggle(event) {
      panel.value.toggle(event);
    }

    function promptDownloadForm() {
      if (!store.state.dlFormSubmitted) {
        showDlForm.value = !showDlForm.value;
      } else {
        exportData();
      }
    }

    function exportData() {
      if (table.value) {
        event("export");
        exportZip(headers.value, store.state.mapTaxon);
      }
    }

    watch(() => store.state.dlFormSubmitted, exportData);

    return {
      panel,
      headers,
      table,
      showDlForm,
      species: computed(() => store.state.mapTaxon),
      toggle,
      promptDownloadForm,
    };
  },
};
</script>

<style scoped>
.vert-center {
  vertical-align: middle;
}
</style>
