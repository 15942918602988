import Basemap from "@arcgis/core/Basemap";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import ImageLayer from "@arcgis/core/layers/ImageryLayer";
import colorMap from "@/assets/recolormap.json";
import Color from "@arcgis/core/Color";

const ADMIN_AREA_RENDERER = {
  type: "simple", // autocasts as new SimpleRenderer()
  symbol: {
    type: "simple-fill", // autocasts as new SimpleMarkerSymbol()
    style: "none",
    outline: {
      // autocasts as new SimpleLineSymbol()
      width: 0.5,
      color: "black",
    },
  },
};

//export const COUNTRY_LAYER = Object.freeze( new MapImageLayer({
//   url: "https://pdi.scinet.usda.gov/mapping/rest/services/CWR/NA_Political_Boundaries_0_Map_Image/MapServer",
//   title: "Country",
//   visible: true,
//   maxScale: 18489297.737237,
//   minScale: 591657527.591555,
//   renderer: ADMIN_AREA_RENDERER
// }));
export const STATE_LAYER = new FeatureLayer({
  url: "https://pdi.scinet.usda.gov/mapping/rest/services/CWR/NA_Political_Boundaries_1_Map_Image/MapServer",
  title: "State/Province",
  visible: true,
  maxScale: 577790.554289,
  minScale: 591657527.591555,
  renderer: ADMIN_AREA_RENDERER,
});

export const COUNTY_LAYER = new FeatureLayer({
  url: "https://pdi.scinet.usda.gov/mapping/rest/services/CWR/NA_Political_Boundaries_2_Map_Image/MapServer/19",
  title: "County/Municipality",
  visible: true,
  maxScale: 0,
  minScale: 2311162.217155,
  renderer: ADMIN_AREA_RENDERER,
});

// Eco Tmp Section
const ECOREGION_POPUP_TEMPLATE = {
  // autocasts as new PopupTemplate()
  title: "Ecoregion: {ECO_NAME}",
  content: "{ECODE_NAME}",
};

const ECOREGION_POPUP_LABEL_CLASS = {
  // autocasts as new LabelClass()
  symbol: {
    type: "text", // autocasts as new TextSymbol()
    color: "green",
    font: {
      // autocast as new Font()
      family: "Playfair Display",
      size: 8,
      weight: "bold",
    },
  },
  labelPlacement: "always-horizontal",
  labelExpressionInfo: {
    expression: "$feature.ECO_NAME",
  },
  maxScale: 0,
  minScale: 19000000,
};

//let color = new Color([0, 255, 0]);

const getEcoRenderer = () => {
  let renderer = {
    type: "unique-value",  // autocasts as new UniqueValueRenderer()
    field: "eco_id_u",
    defaultSymbol: { type: "simple-fill" },  // autocasts as new SimpleFillSymbol()
    uniqueValueInfos: 
    colorMap.map(o => {
      return {
        value: o.region.toString(),
        symbol: {
          type: "simple-fill",
          color: new Color([o.r, o.g, o.b, 0.5])
        }
      }
    })
  };
  return renderer;
}


export const RICHNESS_LAYER = new MapImageLayer({
  url: "https://pdi.scinet.usda.gov/hosting/rest/services/Species_Richness_NA_Revised/MapServer",
  title: "Richness Map",
  sublayers:[{id: 62, title:"Richness Across All Taxa"}],
  opacity: 0.75,
});

export const ECOREGION_LAYER = new FeatureLayer({
  url: "https://pdi.scinet.usda.gov/hosting/rest/services/Hosted/Ecoregions_North_Central_Americas/FeatureServer",
  title: "Ecoregions",
  visible: false,
  popupTemplate: ECOREGION_POPUP_TEMPLATE,
  labelingInfo: ECOREGION_POPUP_LABEL_CLASS,
  renderer: getEcoRenderer(),
  opacity: 0.5,
});
export const PROTAREA_LAYER = new ImageLayer({
  url: "https://pdi.scinet.usda.gov/image/rest/services/Protected_Areas/ImageServer",
  title: "Protected Areas",
  visible: false,
  opacity: 0.5,
});
