<template>
  <div class="text-center content-size line-height-3">
    For additional information or questions about this project please contact:
    <p>
      <a
        class="no-underline"
        href="mailto:anne.frances@usda.gov"
        target="_blank"
        >Anne Frances
      </a>
      or
      <a
        class="no-underline"
        href="mailto:ckhoury@sdbgarden.org"
        target="_blank"
      >
        Colin Khoury
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  setup() {
    return {};
  },
};
</script>

<style></style>
