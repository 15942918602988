import { createApp } from "vue";
import App from "./App.vue";

import PrimeVue from "primevue/config";

import "primevue/resources/themes/saga-green/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";

import { createRouter, createWebHashHistory } from "vue-router";

import VueGtag from "vue-gtag";

import store from "./store/store.js";

const routes = [{ path: "/", component: App }];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

createApp(App)
  .directive("tooltip", Tooltip)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(ToastService)
  .use(VueGtag, {
    config: { id: "G-919R3EWD8L" }
  })
  .mount("#app");
