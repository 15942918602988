<template>
  <TabView v-if="Species">
    <TabPanel header="Taxon Info">
      <div class="grid">
        <div class="col-4 font-bold">Taxon</div>
        <div class="col-8 text-center">{{ Species.Taxon }}</div>
        <div class="col-4 font-bold">Category</div>
        <div class="col-8 text-center">
          {{ getCategoryPrettyName(Species.Category) }}
        </div>
        <div class="col-4 font-bold">Associated Crop Categories</div>
        <div class="col-8 text-center">
          {{ Species["Associated crop type general"] }} -
          {{ Species["Associated crop type specific"] }}
        </div>
        <div class="col-4 font-bold">Crop</div>
        <div class="col-8 text-center">
          {{ Species["Associated crop common name"] }}
        </div>
        <div class="col-4 font-bold">GRIN Global Link</div>
        <div class="col-8 text-center">
          <a
            :href="`https://npgsweb.ars-grin.gov/gringlobal/taxon/taxonomydetail?id=${Species['GRIN Global ID']}`"
            target="_blank"
          >
            {{ `${Species.Taxon} at GRIN Global` }}
          </a>
        </div>
      </div>
    </TabPanel>
    <TabPanel header="Records">
      <div class="grid">
        <div class="col-6 font-bold">Total Records</div>
        <div class="col-6 text-center">
          {{ getNumLocale(Species["Total Records"]) }}
        </div>
        <div class="col-6 font-bold">Records with coordinates</div>
        <div class="col-6 text-center">
          {{ getNumLocale(Species["Records with coordinates"]) }}
        </div>
        <div class="col-6 font-bold">
          Ex situ conservation records
          <i
            class="pi pi-info-circle"
            v-tooltip.bottom="
              'Number of accessions/samples currently maintained in seedbanks, genebanks, or botanic gardens'
            "
          />
        </div>
        <div class="col-6 text-center">
          {{ getNumLocale(Species["Total G records"]) }}
        </div>
        <div class="col-6 font-bold">
          Ex situ conservation records with coordinates
          <i
            class="pi pi-info-circle"
            v-tooltip.bottom="
              'Number of accessions/samples currently maintained in seedbanks, genebanks, or botanic gardens with coordinate information (where originally collected)'
            "
          />
        </div>
        <div class="col-6 text-center">
          {{ getNumLocale(Species["Total G records with coordinates"]) }}
        </div>
        <div class="col-6 font-bold">
          Reference records
          <i
            class="pi pi-info-circle"
            v-tooltip.bottom="
              'Number of herbarium or other records of sightings of the taxon'
            "
          />
        </div>
        <div class="col-6 text-center">
          {{ getNumLocale(Species["Total H Records"]) }}
        </div>
        <div class="col-6 font-bold">
          Reference records with coordinates
          <i
            class="pi pi-info-circle"
            v-tooltip.bottom="
              'Number of herbarium or other records of sightings of the taxon with coordinate information (where originally sighted)'
            "
          />
        </div>
        <div class="col-6 text-center">
          {{ getNumLocale(Species["Total H with coordinates"]) }}
        </div>
        <div class="col-6 font-bold">Records in native area</div>
        <div class="col-6 text-center">
          {{ getNumLocale(Species["Total occurrences in target native area"]) }}
        </div>
        <div class="col-6 font-bold">
          Ex situ conservation records in native area
        </div>
        <div class="col-6 text-center">
          {{
            getNumLocale(Species["Total G occurrences in target native area"])
          }}
        </div>
        <div class="col-6 font-bold">Reference records in native area</div>
        <div class="col-6 text-center">
          {{
            getNumLocale(Species["Total H occurrences in target native area"])
          }}
        </div>
      </div>
    </TabPanel>
    <TabPanel header="Conservation Status">
      <div class="grid">
        <div class="col-6">
          <Card
            :class="`border-3 ${getPriorityColor(
              Species['FCSex priority category']
            )}`"
          >
            <template #title> Ex situ </template>
            <template #content>
              <div class="text-xs md:text-base font-semibold line-height-1">
                Sampling representativeness score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Ratio of ex situ records to reference records, on a scale from 0 (poor) to 100 (excellent)'
                  "
                />
              </div>
              <br />
              <div class="ttext-xs md:text-base text-center line-height-1">
                {{ getDecFixedLocale(Species["SRSex"], 1) }}
              </div>
              <br />
              <div class="text-xs md:text-base font-semibold line-height-1">
                Geographic representativeness score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Extent to which ex situ records cover the geographic distribution of the taxon, on a scale from 0 (poor) to 100 (excellent)'
                  "
                />
              </div>
              <br />
              <div class="text-xs md:text-base text-center line-height-1">
                {{ getDecFixedLocale(Species["GRSex"], 1) }}
              </div>
              <br />
              <div class="text-xs md:text-base font-semibold line-height-1">
                Ecological representativeness score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Extent to which ex situ records cover the variety of ecoregions occupied by the taxon, on a scale from 0 (poor) to 100 (excellent)'
                  "
                />
              </div>
              <br />
              <div class="text-xs md:text-base text-center line-height-1">
                {{ getDecFixedLocale(Species["ERSex"], 1) }}
              </div>
              <br />
              <div class="text-xs md:text-base font-semibold line-height-1">
                Final conservation score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Final assessment of the extent to which ex situ records cover the geographic and ecological range of the taxon,  on a scale from 0 (poor) to 100 (excellent). This is an average of the three ex situ scores'
                  "
                />
              </div>
              <br />
              <div class="text-xs md:text-base text-center line-height-1">
                {{
                  getNumStringConcat({
                    rankValue: Species["FCSex"],
                    catValue: Species["FCSex priority category"],
                  })
                }}
              </div>
            </template>
          </Card>
        </div>

        <div class="col-6">
          <Card
            :class="`border-3 ${getPriorityColor(
              Species['FCSin priority category']
            )}`"
          >
            <template #title> In situ </template>
            <template #content>
              <div class="text-xs md:text-base font-semibold line-height-1">
                Sampling representativeness score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Proportion of total records with coordinates of the taxon that were found in protected areas, on a scale from 0 (poor) to 100 (excellent)'
                  "
                />
              </div>
              <br />
              <div class="text-xs md:text-base text-center line-height-1">
                {{ getDecFixedLocale(Species["SRSin"], 1) }}
              </div>
              <br />
              <div class="text-xs md:text-base font-semibold line-height-1">
                Geographic representativeness score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Extent to which distribution model of the taxon occurs within a protected area, on a scale from 0 (poor) to 100 (excellent)'
                  "
                />
              </div>
              <br />
              <div class="text-xs md:text-base text-center line-height-1">
                {{ getDecFixedLocale(Species["GRSin"], 1) }}
              </div>
              <br />
              <div class="text-xs md:text-base font-semibold line-height-1">
                Ecological representativeness score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Extent to which the distribution model of the taxon occuring within protected areas covers the variety of ecoregions occupied by the taxon, on a scale from 0 (poor) to 100 (excellent)'
                  "
                />
              </div>
              <br />
              <div class="text-xs md:text-base text-center line-height-1">
                {{ getDecFixedLocale(Species["ERSin"], 1) }}
              </div>
              <br />
              <div class="text-xs md:text-base font-semibold line-height-1">
                Final conservation score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Final assessment of the extent to which protected areas cover the geographic and ecological range of the taxon,  on a scale from 0 (poor) to 100 (excellent). This is an average of the three in situ scores'
                  "
                />
              </div>
              <br />
              <div class="text-xs md:text-base text-center line-height-1">
                {{
                  getNumStringConcat({
                    rankValue: Species["FCSin"],
                    catValue: Species["FCSin priority category"],
                  })
                }}
              </div>
            </template>
          </Card>
        </div>
        <div class="col-12">
          <Card
            :class="`border-3 ${getPriorityColor(
              Species['FCSc mean priority category']
            )}`"
          >
            <template #title>
              <div class="text-center">Combined Status</div>
            </template>
            <template #content>
              <div class="text-base font-semibold line-height-1 text-center">
                Final conservation score
                <i
                  class="pi pi-info-circle"
                  v-tooltip.bottom="
                    'Average of the final ex situ and final in situ conservation scores'
                  "
                />
              </div>
              <br />
              <div class="text-base text-center line-height-1">
                {{
                  getNumStringConcat({
                    rankValue: Species["FCSc mean"],
                    catValue: Species["FCSc mean priority category"],
                  })
                }}
              </div>
              <br />
            </template>
          </Card>
        </div>
      </div>
    </TabPanel>
    <TabPanel header="Threat Assessment">
      <Card class="border-3 mb-3">
        <template #title>
          <div class="text-left">Preliminary Assessment Data</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-6 font-bold">
              Extent of occurrence [km<sup>2</sup>]
              <i
                class="pi pi-info-circle"
                v-tooltip.bottom="
                  'Total area in square kilometers calculated by drawing a shape encompassing all records with coordinates'
                "
              />
            </div>
            <div class="col-6 text-center">
              {{
                getNumStringConcat({
                  rankValue: Species["EOO area km2"],
                  stringValue: Species["EOO status"],
                })
              }}
            </div>
            <div class="col-6 font-bold">
              Area of occupancy [km<sup>2</sup>]
              <i
                class="pi pi-info-circle"
                v-tooltip.bottom="
                  'Total area in square kilometers of buffer zones around each occurrence point with coordinates. The buffer zone is created by centering each point in 2km by 2km square grid.'
                "
              />
            </div>
            <div class="col-6 text-center">
              {{
                getNumStringConcat({
                  rankValue: Species["AOO adjusted minimum"],
                  stringValue: Species["AOO status"],
                })
              }}
            </div>
          </div>
        </template>
      </Card>
      <Card class="border-3 mt-3">
        <template #title>
          <div class="text-left">Supplementary Info</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-6 font-bold">
              <a href="https://explorer.natureserve.org/" target="_blank"
                >NatureServe Global Rank</a
              >
            </div>
            <div class="col-6 text-center">
              {{
                getNatureRank(
                  Species["NatureServe ROUNDED G RANK"],
                  Species["NatureServe GRANK REV YR"]
                )
              }}
            </div>
            <div class="col-6 font-bold">
              <a href="https://ecos.fws.gov/ecp/" target="_blank"
                >US Endangered Species Act Status</a
              >
            </div>
            <div class="col-6 text-center">
              {{ getUSEndangeredSpecActRank(Species["USESA STATUS"]) }}
            </div>
            <div class="col-6 font-bold">
              <a href="https://www.iucnredlist.org/" target="_blank">IUCN Red List Category</a>
            </div>
            <div class="col-6 text-center">
              {{
                getRedList(
                  Species["Red List Category"],
                  Species["Red List year published"]
                )
              }}
            </div>
          </div>
        </template>
      </Card>
    </TabPanel>
  </TabView>
</template>

<script>
import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Card from "primevue/card";

import {
  getCategoryPrettyName,
  getNumStringConcat,
  getUSEndangeredSpecActRank,
  getPriorityColor,
  getNatureRank,
  getRedList,
} from "../utils/nameUtils.js";

import { getDecFixedLocale, getNumLocale } from "../utils/numUtils.js";

export default {
  name: "DetailedSpecies",
  components: {
    TabView,
    TabPanel,
    Card,
  },
  props: {
    Species: Object,
  },
  setup() {
    return {
      getCategoryPrettyName,
      getNumStringConcat,
      getUSEndangeredSpecActRank,
      getPriorityColor,
      getNatureRank,
      getRedList,
      getDecFixedLocale,
      getNumLocale,
    };
  },
};
</script>

<style>
.urgent-priority {
  border-color: hsl(0, 100%, 50%) !important;
}

.high-priority {
  border-color: hsl(36, 100%, 50%);
}

.medium-priority {
  border-color: hsl(60, 100%, 50%);
}

.low-priority {
  border-color: hsl(120, 60%, 50%);
}
</style>
