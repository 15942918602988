export default Object.freeze({
  DATA_DISPLAY_VERSION: "CWR-2020",
  PI_TEAM: [
    {
      alt: "Colin Khoury",
      img: "Colin_Khoury.jpg",
      name: "Colin Khoury",
      info: "Colin is the Senior Director of Science and Conservation at the San Diego Botanic Garden and is a visiting researcher at the International Center for Tropical Agriculture (CIAT), a CGIAR international agricultural research center. He previously worked at USDA, Saint Louis University, and the Global Crop Diversity Trust.",
      fav: "Colin loves all wild relatives, but has a particular affection for the wild Phaseolus beans inhabiting the desert washes of Aridoamerica.",
      association: "San Diego Botanic Garden",
      url: "https://sdbgarden.org/ScienceandConservationTeam.htm",
      refName: "San Diego Botanic Garden Science and Conservation Team",
    },
    {
      alt: "Dan Carver",
      img: "Daniel_Carver.jpg",
      name: "Dan Carver",
      info: "Dan Carver is a spatial scientist working out of Northern Colorado.",
      fav: "While working on this project, Malus fusca, the pacific crab apple, led me down an unexpected and enlightening road through history.",
      association: "USDA - Agricultural Research Service",
      url: "https://gis.colostate.edu/about/staff/",
      refName: "Colorado State University",
    },
    {
      alt: "Stephanie Greene",
      img: "Stephanie_Greene.jpg",
      name: "Stephanie Greene",
      info: "Stephanie is the seed curator at the USDA ARS  National Laboratory for Genetic Resources Preservation in Fort Collins, Colorado.",
      fav: "While hiking, Stephanie always admires the many wild crop cousins she sees around her, especially the plum relative, Prunus americanus, which makes tasty jam.",
      association: "USDA - Agricultural Research Service",
      url: "https://www.ars.usda.gov/plains-area/fort-collins-co/center-for-agricultural-resources-research/paagrpru/people/stephanie-greene/",
      refName: "Stepanie Green at the USDA",
    },
    {
      alt: "Karen Williams",
      img: "Karen_Williams.jpg",
      name: "Karen Williams",
      info: "Karen is a botanist with the USDA ARS National Germplasm Resources Laboratory in Beltsville, Maryland.  She is excited to see crop wild relatives wherever they grow, but especially likes visiting undisturbed populations deep in the protected areas of the United States.",
      fav: "One of Karen's favorite crop relatives is the American persimmon, Diospyros virginiana, whose bright orange fruits are also very popular with many birds and mammals.",
      association: "USDA - Agricultural Research Service",
      url: "https://www.ars.usda.gov/people-locations/person/?person-id=6089",
      refName: "Karen Williams at the USDA",
    },
    {
      alt: "Anne Frances",
      img: "Anne_Frances.jpg",
      name: "Anne Frances",
      info: "Anne is a botanist with the USDA ARS National Germplasm Resources Laboratory in Beltsville, Maryland. She also serves as the IUCN Red List Authority for North America Plants, and has been working on assessing the conservation status of the grape genus (Vitis) in North America.",
      fav: "Anne is quite taken with the rock grape, Vitis rupestris, that is used as rootstock for the cultivated grape.",
      association: "USDA - Agricultural Research Service",
      url: "https://www.ars.usda.gov/northeast-area/beltsville-md-barc/beltsville-agricultural-research-center/national-germplasm-resources-laboratory/",
      refName: "Anne Frances at the USDA (coming soon)",
      cropimg: "Vitis_rupestris_The_Grapes_of_New_York_1908.jpg",
      cropalt: "vitis rupestris",
    },
  ],
});
