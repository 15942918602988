<template>
  <Button
    :label="buttonTitle"
    icon="pi pi-chevron-down"
    iconPos="right"
    class="p-button-text p-button-plain toolbar-button font-bold transition-none"
    aria-controls="panel"
    aria:haspopup="true"
    :badge="`${badgeCounter == 0 ? '' : '+' + badgeCounter.toString()}`"
    badgeClass="p-badge"
    @click="toggle"
    :aria-expanded="panel?.visible ?? false"
  />

  <div class="lgScreen">
    <OverlayPanel
      v-if="mounted"
      ref="panel"
      :appendTo="appendTo"
      style="width: 25vw"
      :baseZIndex="1000"
      :autoZIndex="true"
      :showCloseIcon="true"
      :dismissable="true"
      :ariaCloseLabel="panelAriaCloseLabel"
      @hide="toggleBtnColors"
      class="border-noround"
    >
      <slot> </slot>
    </OverlayPanel>
  </div>
  <div class="smScreen">
    <slot> </slot>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useWindowSize } from "vue-window-size";

import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

export default {
  name: "FilterWrapper",
  components: {
    Button,
    OverlayPanel,
  },
  props: {
    appendTo: String,
    buttonTitle: String,
    panelAriaCloseLabel: String,
  },
  emits: ["clicked"],

  setup(props, { emit }) {
    var white = "hsl(360, 100%, 100%)";
    var green = "hsl(130, 100%, 20%)";
    var trans = "transparent";

    const btnColor = ref(trans);
    const txtColor = ref(white);

    const badgeCounter = ref(0);
    const panel = ref();
    const togglePanel = ref("none");

    const { width, height } = useWindowSize();

    const mounted = ref(false);

    onMounted(() => {
      mounted.value = true;
    });

    function toggleBtnColors() {
      btnColor.value = panel.value.visible ? white : trans;
      txtColor.value = panel.value.visible ? green : white;
    }

    function toggle(event) {
      if (width.value <= 960) {
        togglePanel.value = togglePanel.value == "block" ? "none" : "block";
      } else {
        panel.value.toggle(event);
      }
      toggleBtnColors();
      emit("clicked");
    }

    function hide() {
      if (width.value <= 960) {
        togglePanel.value = "none";
      } else {
        panel.value.hide();
      }
    }

    function setCounter(value) {
      badgeCounter.value = value;
    }

    return {
      btnColor,
      txtColor,
      mounted,
      badgeCounter,
      panel,
      toggle,
      togglePanel,
      hide,
      setCounter,
      toggleBtnColors,
    };
  },
};
</script>

<style scoped>
.toolbar-button.p-button.p-button-text {
  background-color: v-bind(btnColor);
  color: v-bind(txtColor);
}

@media screen and (max-width: 960px) {
  .smScreen {
    display: v-bind(togglePanel);
  }
  .lgScreen {
    display: none;
  }

  .toolbar-button.p-button.p-button-text {
    background-color: hsl(130, 100%, 20%);
    color: hsl(360, 100%, 100%);
  }
  .toolbar-button.p-button.p-button-text {
    width: 100%;
  }
  .toolbar-button.p-button.p-button-text.p-button-plain:enabled:hover {
    background: hsl(130, 100%, 20%);
    color: white;
  }

  .p-button.p-button-text.p-button-plain:enabled:hover {
    color: hsl(130, 100%, 20%);
    background: white;
  }
}
@media screen and (min-width: 961px) {
  .smScreen {
    display: none;
  }
  .lgScreen {
    display: block;
  }
  .toolbar-button.p-button.p-button-text.p-button-plain:enabled:hover {
    background: white;
    color: hsl(130, 100%, 20%);
  }

  .p-button.p-button-text.p-button-plain:enabled:hover {
    background: hsl(130, 100%, 20%);
    color: white;
  }
}
</style>
