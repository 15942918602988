<template>
  <Galleria
    :auto-play="true"
    :circular="true"
    indicators-position="top"
    :numVisible="5"
    :responsiveOptions="responsiveOptions"
    thumbnails-position="top"
    :value="images"
  >
    <template #item="{ item }">
      <img :src="item.src" :alt="item.alt" />
    </template>
    <template #thumbnail="{ item }">
      <div class="justify-content-center">
        <img :src="item.src" :alt="item.alt" />
      </div>
    </template>
    <template #caption="{ item }">
      <p>{{ item.alt }}</p>
    </template>
  </Galleria>
</template>

<script>
import Galleria from "primevue/galleria";

import images from "./images";

export default {
  name: "ImageSlideshow",
  components: {
    Galleria,
  },
  setup() {
    return {
      images,
    };
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
  display: block;
}
</style>
