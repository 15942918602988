<template>
  <div class="grid content-size">
    <div class="col-12 grid" v-for="pi in CONSTANTS.PI_TEAM" :key="pi.name">
      <div class="grid-fixed align-self-center" style="width: 175px">
        <img
          :src="require(`@/assets/img/contributors/${pi.img}`)"
          :alt="pi.alt"
          width="150"
          height="150"
        />
      </div>
      <div class="col">
        <h2 class="mb-1">{{ pi.name }}</h2>
        <h4 class="mt-0 mb-1">{{ pi.association }}</h4>
        <div class="mb-3">
          <a :href="pi.url" target="_blank">{{ pi.refName }}</a>
        </div>
        <div class="text-justify line-height-3 mb-2">
          <strong>About:</strong>
          <br />
          {{ pi.info }}
        </div>
        <div class="text-justify line-height-3">
          <strong>Favorite Crop Wild Relative:</strong>
        </div>
        <div class="grid grid-nogutter">
          <div class="col-9">
            <div class="text-justify line-height-3">
              {{ pi.fav }}
            </div>
          </div>
          <div class="col-3">
            <figure class="m-0 ml-5 p-0">
              <div>
                <Image
                  v-if="pi.cropimg"
                  :src="require(`@/assets/img/${pi.cropimg}`)"
                  :alt="pi.cropalt"
                  width="65"
                  height="65"
                  preview
                />
              </div>
              <figcaption>
                <em>{{ pi.cropalt }}</em>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <Divider class="m-0 p-0 mt-1 mb-1" />
    </div>
  </div>
</template>

<script>
import Divider from "primevue/divider";
import Image from "primevue/image";
import CONSTANTS from "../../configs/constants";

export default {
  name: "Contributors",
  components: { Divider, Image },
  setup() {
    return { CONSTANTS };
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>
