import colormap from "../../assets/recolormap.json";

function mulberry32(a) {
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

const createColorDict = function () {
  let dict = {};
  for (let color of colormap) {
    dict[color.region] = [color.r, color.g, color.b];
  }
  return dict;
};

const colordict = createColorDict();

export const colorize = function (pixelData) {
  // If there isn't pixelData, a pixelBlock, nor pixels, exit the function
  if (
    pixelData === null ||
    pixelData.pixelBlock === null ||
    pixelData.pixelBlock.pixels === null
  ) {
    return;
  }

  // The pixelBlock stores the values of all pixels visible in the view
  let pixelBlock = pixelData.pixelBlock;

  // Get the min and max values of the data in the current view
  let minValue = pixelBlock.statistics[0].minValue;
  let maxValue = pixelBlock.statistics[0].maxValue;

  // The mask is an array that determines which pixels are visible to the client
  let mask = pixelBlock.mask;

  // The pixels visible in the view
  let pixels = pixelBlock.pixels;

  // // The number of pixels in the pixelBlock
  let numPixels = pixelBlock.width * pixelBlock.height;

  // Calculate the factor by which to determine the red and blue
  // values in the colorized version of the layer
  let factor = 255.0 / (maxValue - minValue);

  // Get the pixels containing temperature values in the only band of the data
  let band1 = pixels[0];
  // // Create empty arrays for each of the RGB bands to set on the pixelBlock
  let rBand = [];
  let gBand = [];
  let bBand = [];

  // Loop through all the pixels in the view
  for (var i = 0; i < numPixels; i++) {
    // Get the pixel value recorded at the pixel location
    let tempValue = parseInt(band1[i]);
    // Calculate the red value based on the factor
    if (tempValue > 0) {
      let color = colordict[tempValue];
      rBand[i] = color[0];
      gBand[i] = color[1];
      bBand[i] = color[2];
    }
    else {
      rBand[i] = 0;
      gBand[i] = 0;
      bBand[i] = 0;
    }
  }

  // // Set the new pixel values on the pixelBlock (now three bands)
  pixelData.pixelBlock.pixels = [rBand, gBand, bBand];
  pixelData.pixelBlock.pixelType = "u8"; // u8 is used for color
};
