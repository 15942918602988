<template>
  <div class="lg-species flex-species">
    <div class="list-box-flex">
      <Toast
        position="bottom-left"
        :breakpoints="{ '960px': { width: '100%', right: '0', left: '0' } }"
        group="spec-limit"
      />
      <TreeTable
        ref="table"
        :value="nodeTaxaData"
        :indentation="0"
        selectionMode="multiple"
        v-model:selectionKeys="selectedTaxa"
        :filters="taxaFilters"
        :metaKeySelection="false"
        filterMode="lenient"
        class="h-full"
        :style="`max-height: ${listMaxHeight};`"
      >
        <template #header>
          <div
            :class="`p-input-icon-left ${
              taxaFilters['global'] ? 'p-input-icon-right' : ''
            }`"
          >
            <i class="pi pi-search" />
            <InputText
              v-model="taxaFilters['global']"
              placeholder="Search"
              class="w-full"
              size="100"
            />
            <i
              v-if="taxaFilters['global']"
              class="pi pi-times transition-colors transition-duration-150 hover:bg-orange-500"
              @click="taxaFilters['global'] = null"
            />
          </div>
        </template>
        <Column field="Taxon" header="Select Species" :expander="true"></Column>
        <template #empty>No Taxa Matching Filters</template>
      </TreeTable>
    </div>
    <Button
      class="border-noround w-full h-full"
      :class="selectedTaxa ? 'p-button-success' : 'p-button-secondary'"
      label="Map Selected Species"
      icon="pi pi-map"
      @click="mapSelected()"
      :disabled="!selectedTaxa"
    />
  </div>
  <div class="sm-species h-full w-full">
    <Button
      label="Select Taxa"
      icon="pi pi-arrow-down"
      @click="visibleFull = true"
      :badge="`${numSpec.toString() + ' available'}`"
      badgeClass="p-badge"
      class="h-full w-full border-noround"
    />
  </div>
  <Sidebar v-model:visible="visibleFull" :baseZIndex="1000" position="full">
    <div class="flex flex-column w-full">
      <div class="list-box-flex">
        <TreeTable
          :value="nodeTaxaData"
          :indentation="0"
          selectionMode="multiple"
          v-model:selectionKeys="selectedTaxa"
          :filters="taxaFilters"
          :metaKeySelection="false"
          filterMode="lenient"
          class="h-full"
          :style="`max-height: ${listMaxHeight};`"
        >
          <template #header>
            <div
              :class="`p-input-icon-left ${
                taxaFilters['global'] ? 'p-input-icon-right' : ''
              }`"
            >
              <i class="pi pi-search" />
              <InputText
                v-model="taxaFilters['global']"
                placeholder="Search"
                class="w-full"
              />
              <i
                v-if="taxaFilters['global']"
                class="pi pi-times transition-colors transition-duration-150 hover:bg-orange-500"
                @click="taxaFilters['global'] = null"
              />
            </div>
          </template>
          <Column field="Taxon" header="Taxa" :expander="true"></Column>
          <template #empty>No Taxa Matching Filters</template>
        </TreeTable>
      </div>
      <div class="button-flex">
        <Button
          class="border-noround w-full h-3rem"
          :class="selectedTaxa ? 'p-button-success' : 'p-button-secondary'"
          label="Map Selected Species"
          icon="pi pi-map"
          @click="mapSelected()"
          :disabled="!selectedTaxa"
        />
      </div>
    </div>
  </Sidebar>
</template>

<script>
import Button from "primevue/button";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Sidebar from "primevue/sidebar";
import Toast from "primevue/toast";
import TreeTable from "primevue/treetable";
import { useToast } from "primevue/usetoast";
import { ref, watch, computed } from "vue";
import { event } from "vue-gtag";
import { useWindowSize } from "vue-window-size";
import { useStore } from "vuex";

import json from "@/assets/cwr.json";
import taxa_geo from "@/assets/taxa_geography.json";

export default {
  name: "Species",
  components: {
    TreeTable,
    InputText,
    Column,
    Button,
    Sidebar,
    Toast,
  },
  setup() {
    const store = useStore();
    const baseData = json;
    const selectedTaxa = ref();
    const taxaFilters = ref({});
    const table = ref();
    const visibleFull = ref(false);

    const speciesData = ref(
      baseData.map((o) => ({
        name: o.Taxon,
        val: o.Taxon,
      }))
    );

    function createGenusNodes(list) {
      return [...new Set(list.map((item) => item.Genus))].map((genus) => {
        return {
          key: genus,
          data: {
            Taxon: genus,
          },
          selectable: false,
          children: list
            .filter((o) => o.Genus == genus)
            .map((tx) => {
              return {
                key: tx.Taxon,
                selectable: true,
                data: {
                  Taxon: tx.Taxon,
                },
              };
            }),
        };
      });
    }
    const nodeTaxaData = ref(createGenusNodes(baseData));
    const numSpec = ref(speciesData.value.length);

    function taxonFilter(tmpData) {
      let taxonCfg = store.state.taxFilter;

      var genus = taxonCfg["genus"]?.map((o) => o.code);
      var crops = taxonCfg["crop"]?.map((o) => o.code);
      var cats = taxonCfg["category"]?.map((o) => o.code);
      var types = taxonCfg["type"]?.map((o) => o.code);
      if (genus?.length > 0) {
        tmpData = tmpData.filter((o) => genus.includes(o.Genus));
      }
      if (crops?.length > 0) {
        tmpData = tmpData.filter((o) =>
          crops.includes(o["Associated crop common name"])
        );
      }
      if (cats?.length > 0) {
        tmpData = tmpData.filter((o) => cats.includes(o["Category"]));
      }

      if (types?.length > 0) {
        let genCats = types.map((o) => o.general);
        let specCats = types.map((o) => o.specific);
        tmpData = tmpData.filter(
          (o) =>
            genCats.includes(o["Associated crop type general"]) &&
            specCats.includes(o["Associated crop type specific"])
        );
      }
      return tmpData;
    }

    function consFilter(tmpData) {
      let consCfg = store.state.consFilter;

      var insitu = consCfg["insitu"]?.map((o) => o.code);
      var exsitu = consCfg["exsitu"]?.map((o) => o.code);
      var comb = consCfg["combined"]?.map((o) => o.code);
      if (insitu?.length > 0) {
        tmpData = tmpData.filter((o) =>
          insitu.includes(o["FCSin priority category"])
        );
      }
      if (exsitu?.length > 0) {
        tmpData = tmpData.filter((o) =>
          exsitu.includes(o["FCSex priority category"])
        );
      }
      if (comb?.length > 0) {
        tmpData = tmpData.filter((o) =>
          comb.includes(o["FCSc mean priority category"])
        );
      }
      return tmpData;
    }

    function geoFilter(tmpData) {
      let geoCfg = store.state.geoFilter;

      if (geoCfg["counties"]?.length > 0) {
        let counties = geoCfg["counties"];
        let taxa = taxa_geo
          .filter((o) => {
            return counties.some((cty) =>
              o["County_Municipality"].includes(cty["name"])
            );
          })
          .map((o) => o["Taxon"]);

        if (taxa?.length > 0) {
          tmpData = tmpData.filter((o) => taxa.includes(o["Taxon"]));
        }
      } else if (geoCfg["state"]) {
        let state = geoCfg["state"]["name"];
        let taxa = taxa_geo
          .filter((o) => o["State_Province"].includes(state))
          .map((o) => o["Taxon"]);

        if (taxa?.length > 0) {
          tmpData = tmpData.filter((o) => taxa.includes(o["Taxon"]));
        }
      } else if (geoCfg["country"]) {
        let countryName = geoCfg["country"]["name"];
        let taxa = taxa_geo
          .filter((o) => o["Country"].includes(countryName))
          .map((o) => o["Taxon"]);

        if (taxa?.length > 0) {
          tmpData = tmpData.filter((o) => taxa.includes(o["Taxon"]));
        }
      }

      return tmpData;
    }

    function filterSpeciesData() {
      let tmpData = baseData;
      tmpData = taxonFilter(tmpData);
      tmpData = consFilter(tmpData);
      tmpData = geoFilter(tmpData);
      nodeTaxaData.value = createGenusNodes(tmpData);
      numSpec.value = tmpData.length;
      selectedTaxa.value = null;
    }

    function mapSelected() {
      event("map");

      if (selectedTaxa.value) {
        let keys = Object.keys(selectedTaxa.value);
        store.commit(
          "updateTaxon",
          baseData.filter((o) => keys.includes(o.Taxon))
        );
        visibleFull.value = false;
      }
    }

    const { width, height } = useWindowSize();

    watch(
      () => [
        store.state.geoFilter,
        store.state.taxFilter,
        store.state.consFilter,
      ],
      filterSpeciesData
    );

    function resetList() {
      if (store.state.mapTaxon.length == 0) {
        selectedTaxa.value = null;
      }
    }

    watch(() => [store.state.mapTaxon], resetList);

    const toast = useToast();

    const speciesLimit = 15;

    function watchSelection() {
      let keys = Object.keys(selectedTaxa?.value ?? {});
      if (keys.length > speciesLimit) {
        let last = keys.pop();
        delete selectedTaxa.value[last];
        // delete last node if selecton > x defined limit for species
        // popup message:
        toast.removeAllGroups();
        toast.add({
          severity: "error",
          summary: "Species Limit Reached",
          detail: `Only ${speciesLimit} species are allowed to be selected at any one time.`,
          group: "spec-limit",
          life: 5000,
        });
      }
    }

    watch(selectedTaxa, watchSelection);

    return {
      table,
      numSpec,
      selectedTaxa,
      nodeTaxaData,
      taxaFilters,
      speciesData,
      filterSpeciesData,
      mapSelected,
      flexMaxHeight: computed(() => `${height.value * 0.75 * 0.05}px`),
      listMaxHeight: computed(() => `${height.value * 0.75 * 0.95}px`),
      visibleFull,
    };
  },
};
</script>

<style scoped>
.flex-species {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .lg-species {
    display: none !important;
  }
}

@media screen and (min-width: 961px) {
  .sm-species {
    position: absolute;
    display: none;
  }
}

.list-box-flex {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 95%;
  overflow: auto;
  min-width: 0;
}

.button-flex {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 5%;
  min-width: 0;
}

div.list-box-flex .p-listbox-list,
div.list-box-flex .p-listbox-list-wrapper {
  height: 100%;
  min-height: 100%;
}
</style>
